$font-normal: 'Hammersmith One', sans-serif;
$font-sketch: 'Cabin Sketch', cursive;
$font-outline: 'Londrina Outline', cursive;

$font-primary: $font-normal;
$font-secondary: $font-sketch;
$font-special: $font-outline;

$color-white: #f6e1e1;
$color-warmcoffe: #7d5a5a;
$color-dcoffe: #382933;
$color-coffe: #4d4646;
$color-red: #fe346e;

$color-primary: $color-red;
$color-secondary: $color-warmcoffe;

$color-background: $color-dcoffe;
$color-border: $color-warmcoffe;
