@import './variables.scss';

.scoreboard {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 4vw;
    align-items: flex-start;
    &--winner {
        color: $color-primary;
        .scoreboard__player__name {
            color: $color-primary;
            transform: scale(1.3);
        }
    }
    &__player {
        display: grid;
        grid-gap: 1vw;
        position: relative;
        text-align: center;
        align-items: flex-start;
        &__name {
            font-weight: 700;
            font-size: 3.2em;
            font-family: $font-special;
            color: $color-white;
            transition: all 0.4s ease-out;
        }
        &__points {
            display: grid;
            grid-gap: 0.3vw;
        }
        &__summary {
            font-family: $font-secondary;
            font-weight: 700;
            font-size: 4em;
        }
    }
}
