@import './variables.scss';

.game-page {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 3vw;
    align-items: center;
    > h1 {
        text-align: center;
        font-size: 3em;
    }
}
