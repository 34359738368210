@import url('https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin+Sketch:wght@700&display=swap');

@import './variables';

::-webkit-scrollbar {
    width: 12px;
}
::-webkit-scrollbar-track {
    background-color: rgba($color-primary, 0.2);
}
::-webkit-scrollbar-thumb {
    background-color: $color-primary;
}

* {
    font-family: $font-primary;
    box-sizing: border-box;
    &::selection {
        background-color: $color-primary;
        color: $color-white;
    }
}
body {
    font-size: 1vw;
    background-color: $color-background;
    font-family: $font-primary;
    color: $color-white;
}

.btn,
select,
input,
button {
    background: none;
    border: 2px solid $color-secondary;
    color: $color-background;
    font-size: 2em;
    border-radius: 2vw;
    padding: 0.1vw 1.2vw;
    &:focus {
        outline: 0;
    }
}
input {
    color: $color-white;
    &::placeholder {
        color: $color-secondary;
    }
}
.btn,
select,
button {
    cursor: pointer;
}
.btn,
select,
button {
    background-color: $color-secondary;
    &:hover {
        background-color: $color-white;
        border-color: $color-white;
    }
}

.btn {
    &-ico {
        border-radius: 50%;
        --size: 1em;
        width: var(--size);
        height: var(--size);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1vw;
        font-size: 1em;
    }
    &-cta {
        font-size: 2.6em;
        --size: 2em;
        background-color: $color-white;
        border-color: $color-white;
        display: flex;
        align-items: center;
        .ico {
            font-size: 0.8em;
            margin-left: 1vw;
        }
    }
}
