@import './variables.scss';

.player-list {
    margin: 2vw 0 1vw;
    &__title {
        color: $color-white;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $color-border;
        padding: 1vw;
        font-size: 2em;
        color: $color-primary;
        &:last-of-type {
            border-bottom: none;
        }
        p {
            font-size: 1.2em;
        }
        button {
            background: none;
            border: 0;
            font-size: 1.2em;
            font-weight: 700;
            cursor: pointer;
            opacity: 0.4;
            color: $color-border;
            margin-left: 2vw;
            &:hover {
                opacity: 1;
                color: $color-white;
            }
        }
    }
}
