@import './variables.scss';

.player-form {
    margin: 0 0 2vw 0;
    form {
        display: grid;
        grid-gap: 1vw;
        grid-auto-flow: column;
    }
}
