@import './variables';
.new-game-page {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2vw;
    .brand {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1vw;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 1vw 0;
        margin: 1vw 0;
        &:after {
            content: '';
            display: block;
            bottom: 0;
            height: 2px;
            background-color: $color-secondary;
            left: 10%;
            right: 10%;
            position: absolute;
        }
        h1 {
            font-size: 10em;
            color: $color-white;
            font-family: $font-special;
            font-weight: 700;
        }
        i {
            font-size: 9em;
            color: $color-white;
        }
    }
}
