@import './variables.scss';

.footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.5vw;
    text-align: right;
    p,
    a {
        color: $color-secondary;
    }
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
